<template>
    <ValidationObserver
        tag="form"
        ref="observer"
        class="comment-edit"
        @submit.prevent="onSubmit"
    >
        <div class="comment-edit__row">
            <ValidationProvider
                tag="div"
                vid="commentText"
                class="comment-edit__col comment-edit__col--left"
                :rules="`required`"
                v-slot="{errors}"
            >
                <textarea-autosize
                    rows="1"
                    ref="editComment"
                    placeholder="Комментировать"
                    class="comment-edit__textarea"
                    :maxlength="1024"
                    v-model="commentText"
                />

                <span class="v-error animated fadeInDown" v-if="errors.length">
                    {{errors[0]}}
                </span>
            </ValidationProvider>

            <div class="comment-edit__col comment-edit__col--right">
                <button
                    type="button"
                    class="comment-edit__trigger comment-edit__submit empty-btn"
                    v-tooltip="`Сохранить`"
                    @click="onSubmit"
                />

                <button
                    type="button"
                    class="comment-edit__trigger comment-edit__cancel empty-btn"
                    v-tooltip="`Отменить`"
                    @click="onCancel"
                />
            </div>
        </div>
    </ValidationObserver>
</template>

<script>
    export default {
        name: 'EditComment',

        props: {
            isComment: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                commentId: this.isComment.id,
                commentText: this.isComment.text
            };
        },

        methods: {
            onSubmit() {
                this.$emit('submit', {
                    id: this.commentId,
                    text: this.commentText
                });
            },

            onCancel() {
                this.$emit('cancel', {
                    id: this.commentId,
                    text: this.commentText
                })
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import '#sass/v-style';

    .comment-edit {
        $p: &;

        &__row {
            margin: -5px;
            display: flex;
            align-items: center;
        }

        &__col {
            margin: 5px;
            display: flex;
            &--left {
                flex-grow: 1;
            }
        }

        &__textarea {
            resize: none;
            outline: none;
            width: 100%;
            padding: 10px 20px;
            border: 1.5px solid $gold;
            &::placeholder {
                font-size: 1rem;
                color: $text-gray;
            }
        }

        &__trigger {
            cursor: pointer;
            align-self: center;
            &:active {
                opacity: 0.7;
            }

            & + #{$p}__trigger {
                margin-left: 10px;
            }
        }

        &__submit {
            @include icon($send-comment-icon, 25px);
        }

        &__cancel {
            @include icon($close-icon-red, 20px);
        }
    }
</style>
